<template>
  <component
    :is="semantic ? `h${level}` : 'div'"
    ref="headingRef"
    class="transform-gpu"
    :class="[
      {
        'font-tech text-head uppercase lg:text-headBig': size === 1,
        'font-tech text-subTechBig uppercase lg:text-headBig': size === 11,
        'font-tech text-subTechBig uppercase lg:text-head': size === 2,
        'font-tech text-subTech uppercase lg:text-headBig': size === 22,
        'font-tech text-subTech1 uppercase lg:text-subTechBig': size === 3,
        'font-tech text-subTech1 uppercase lg:text-head': size === 4,
        'text-sub1 lg:text-sub': size === 5,
        'text-2xl md:text-2xl lg:text-3xl font-tech': size === 6,
        'text-stroke font-fat text-[75px] leading-[0.7] tracking-[0.001em] sm:text-[82px] lg:text-fatBig':
          size === 100
      },
      isMounted ? 'opacity-100' : 'opacity-0'
    ]"
  >
    <span ref="textRef">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { SplitText } from 'gsap/SplitText'
import { gsap } from 'gsap'

interface HeadingProps {
  semantic?: boolean
  level?: 1 | 2 | 3 | 4 | 5 | 6
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 100 | 11 | 22
  isAnimated?: boolean
  delay?: number
  animate?: boolean
  topHeading?: boolean
}

const props = withDefaults(defineProps<HeadingProps>(), {
  semantic: false,
  level: 1,
  size: 1,
  isAnimated: true,
  delay: 0,
  animate: true,
  topHeading: false
})

const headingRef = ref<HTMLElement | null>(null)
const targetIsVisible = useElementVisibility(headingRef)
const textRef = ref<HTMLElement | null>(null)
const split = ref<null | any>(null)
const tween = ref<null | any>(null)
const isMarkerVisible = ref(false)
const isMounted = ref(false)

const { stop } = useIntersectionObserver(
  headingRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && props.animate) {
      tween.value?.play()
    }
  },
  {
    threshold: 0.1
  }
)

watch(targetIsVisible, () => {
  if (targetIsVisible.value && props.animate) {
    tween.value?.play()
  }
})

watch(
  () => props.animate,
  (_, oldValue) => {
    if (!oldValue) {
      tween.value?.play()
    }
  }
)

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{ (event: 'completed'): void }>()

onMounted(() => {
  gsap.registerPlugin(SplitText)
  isMounted.value = true

  if (!props.isAnimated) {
    isMarkerVisible.value = true
  }

  if (textRef.value && props.isAnimated) {
    split.value = new SplitText(textRef.value, {
      type: 'chars,words'
    })

    const childSplit = new SplitText(textRef.value, {
      type: 'chars, words'
    })

    gsap.set(childSplit.words, { perspective: 900 })

    tween.value = gsap.from(childSplit.words, {
      delay: props.delay,
      duration: 0.8,
      autoAlpha: 0,
      filter: 'blur(4px)',
      ease: 'ease.inOut',
      stagger: {
        amount: 0.18,
        from: 'random'
      },
      paused: true,
      onStart: () => {
        isMarkerVisible.value = true
      },
      onComplete: () => {
        emit('completed')
      }
    })

    if (props.topHeading) {
      useTimeoutFn(() => {
        tween.value?.play()
      }, 50)
    }
  }
})

onUnmounted(() => {
  tween?.value?.kill()
  stop()
})
</script>

<style lang="postcss" scoped>
.text-stroke {
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: theme('colors.primary');
}
</style>
